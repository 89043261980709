import ko from 'knockout';
import { KnockoutVueComponentWrapper } from '@/VueCore/utils/knockoutVueWrapper/knockoutVueComponentWrapper';
import KnockoutVueWrapperOptions from '@/VueCore/utils/knockoutVueWrapper/knockoutVueWrapperOptions';
import { Component } from 'vue';

class ComponentRegistrationExtensions {
  constructor() {
    // @ts-expect-error -- Setting our own custom method for re-registering components
    ko.components.reregister = ComponentRegistrationExtensions.reregisterKnockoutComponent;

    // @ts-expect-error -- Setting our own custom property for registering Vue.js components
    ko.vueComponents = {
      register: ComponentRegistrationExtensions.registerVueComponent,
      reregister: ComponentRegistrationExtensions.reregisterVueComponent
    };
  }

  /**
   * Helper function to register components inside other components
   * @param {string} name
   * @param {any} comp
  */
  static reregisterKnockoutComponent(name: string, comp: any) {
    if (ko.components.isRegistered(name)) {
      ko.components.unregister(name);
    }
    ko.components.register(name, comp);
  }

  /**
   * Helper function to register Vue.js components.
   * @param {string} name
   * @param {Vue} component
   * @param {any} opts additional vue component parameters
  */
  static registerVueComponent(name: string, component: Component, opts: KnockoutVueWrapperOptions | undefined = undefined) {
    const knockoutComponent = new KnockoutVueComponentWrapper(component, opts);
    ko.components.register(name, knockoutComponent);
  }

  /**
   * Helper function to reregister Vue.js components.
   * @param {string} name
   * @param {Vue} component
  */
  static reregisterVueComponent(name: string, component: Component) {
    const knockoutComponent = new KnockoutVueComponentWrapper(component);
    ComponentRegistrationExtensions.reregisterKnockoutComponent(name, knockoutComponent);
  }
}

export default new ComponentRegistrationExtensions();