/**
 * @param {object} serverModel
 * @param {string} previousCameraId
 */
function CameraSummaryModel(serverModel, previousCameraId) {

  const self = this;

  // Observables
  self.isRequestLastAccessed = serverModel.cameraId === previousCameraId;

  // Standard properties
  self.cameraId = serverModel.cameraId;
  self.cameraName = serverModel.cameraName;
  self.addedDateTime = serverModel.addedDateTime;
  self.coordinate = {
    latitude: serverModel.coordinate.latitude,
    longitude: serverModel.coordinate.longitude
  };
}

export default CameraSummaryModel;
