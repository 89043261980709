import repositoryHelper from './repositoryHelper';
import contextData from '@/contextData';
import { SelfResponseDefinitionSummaryModel } from '@/Models/selfResponseDefinitionSummaryModel';

const baseResourceUrl = 'api/selfresponsedefinition/';

export default class ResponseFormDefinitionRepository {

  public static getResponseDefinitions = (): Promise<SelfResponseDefinitionSummaryModel[]> => {
    const agencyId = contextData.userData.agencyId;
    const resourceUrl = `${baseResourceUrl}${agencyId}`;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
        .then(function (data) {
          resolve(data);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  public static getResponseFormById = (formId: string) => {
    const agencyId = contextData.userData.agencyId;
    const resourceUrl = `${baseResourceUrl}${agencyId}/${formId}`;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
        .then(function (data) {
          resolve(data);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };
}