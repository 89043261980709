import ko from 'knockout';

import constants from '../constants';

function CctvFolderUploadModel(uploads) {

  const self = this;

  self.uploadStatuses = constants.uploadStatuses;

  self.uploads = uploads;
  self.type = self.uploads[0].type;
  self.folderName = self.uploads[0].rootFolderName;
  self.cameraName = self.uploads[0].cameraName;
  self.comments = self.uploads[0].comments;
  self.selectedItem = self.uploads[0].selectedItem;
  self.addedTimestamp = self.uploads[0].addedTimestamp;

  self.uploadsCount = ko.pureComputed(function () {
    return self.uploads.length;
  });

  self.folderSize = ko.pureComputed(function () {
    return self.uploads.reduce(function (total, upload) {
      return total + upload.fileSize;
    }, 0);
  });

  self.bytesUploaded = ko.pureComputed(function () {
    return self.uploads.reduce(function (total, upload) {
      return total + upload.bytesUploaded();
    }, 0);
  });

  self.statusName = ko.pureComputed(function () {
    if (self.uploads.every(function (file) {
      return file.statusName() === self.uploadStatuses.uploaded;
    })) {
      return self.uploadStatuses.uploaded;
    }
    if (self.uploads.some(function (file) {
      return file.statusName() === self.uploadStatuses.failedAuthorisation;
    })) {
      return self.uploadStatuses.failedAuthorisation;
    }
    if (self.uploads.some(function (file) {
      return file.statusName() === self.uploadStatuses.cancelled;
    })) {
      return self.uploadStatuses.cancelled;
    }
    if (self.uploads.some(function (file) {
      return file.statusName() === self.uploadStatuses.failed;
    })) {
      return self.uploadStatuses.failed;
    }
    if (self.uploads.some(function (file) {
      return file.statusName() === self.uploadStatuses.authorising;
    })) {
      return self.uploadStatuses.authorising;
    }
    if (self.uploads.some(function (file) {
      return file.statusName() === self.uploadStatuses.uploading;
    })) {
      return self.uploadStatuses.uploading;
    }

    return self.uploadStatuses.pending;
  });

  self.isAuthorising = ko.pureComputed(function () {
    return self.statusName() === self.uploadStatuses.authorising;
  });

  self.progress = ko.pureComputed(function () {
    return ((self.bytesUploaded() / self.folderSize()) * 100).toFixed();
  });
}

export default CctvFolderUploadModel;
