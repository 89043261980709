function PageResultModel(serverPageResult, results) {

  const self = this;

  self.page = serverPageResult.page;
  self.pageSize = serverPageResult.pageSize;
  self.total = serverPageResult.total;
  self.filterCriteria = serverPageResult.filterCriteria;
  self.results = results;
}

export default PageResultModel;
