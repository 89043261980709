import SelfResponseModel from '../Models/selfResponseModel';
import repositoryHelper from './repositoryHelper';
const baseResourceUrl = 'api/selfresponse/';

export default class SelfResponseRepository {

  public static getResponse = (responseId: string) => {

    const resourceUrl = baseResourceUrl + responseId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
        .then(function (request) {
          const responseModel = new SelfResponseModel(request);
          resolve(responseModel);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  public static createResponse = (responseDataModel: SelfResponseModel) => {

    const resourceUrl = baseResourceUrl;
    return repositoryHelper.ajaxPost(resourceUrl, responseDataModel);
  };

  public static updateResponse = (responseDataModel: SelfResponseModel) => {

    const resourceUrl = baseResourceUrl + responseDataModel.id;
    return repositoryHelper.ajaxPut(resourceUrl, responseDataModel);
  };

  public static deleteResponse = (responseId:string) => {

    const resourceUrl = baseResourceUrl + responseId;
    return repositoryHelper.ajaxDelete(resourceUrl);
  };

  public static submitSelfResponse(responseId: string, responseDataModel: SelfResponseModel): Promise<SelfResponseModel> {
    const uri = `${baseResourceUrl}submit/${responseId}`;
    return repositoryHelper.ajaxPost(uri, responseDataModel);
  }

  public static selfResponseHasUploadMetadata(responseId: string): Promise<boolean> {
    const uri = `${baseResourceUrl}${responseId}/hasuploadmetadata`;
    return repositoryHelper.ajaxGet(uri);
  }
}