import * as ko from 'knockout';
import 'core-js/stable'; // polyfills for ECMAScript up to 2020
import 'regenerator-runtime/runtime'; // Standalone runtime for Regenerator-compiled generator and async functions
import 'bootstrap-sass'; // Include Bootstrap JS
import KnockoutBindingProvider from '../knockoutBindingProvider';
import { PortalStartupErrorType } from '../Types/Enums/portalStartupErrorType';

/**
 * This module binds Knockout to the DOM to show an error page when there's a data issue that prevents
 * the main SPA from loading (e.g. business is disabled or user is authenticated but not known in CBP).
 * This module is called from "portalStartup" when there's a data issue but it's confirmed the user is authenticated.
 */
export default class PortalStartupErrorHandler {
  static execute(portalStartupErrorType: PortalStartupErrorType): void {
    console.warn('Portal startup error page to be displayed for error type', portalStartupErrorType);

    const pascalToSnakeCase = (str: string): string =>
      str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

    const pageComponentName = pascalToSnakeCase(portalStartupErrorType);

    // remove binding failure fallback loader/error divs
    const fallbackScreensElement = document.getElementById('fallbackScreens');
    fallbackScreensElement?.parentNode.removeChild(fallbackScreensElement);

    KnockoutBindingProvider.registerSecureBindingProvider();

    ko.applyBindings({
      componentName: pageComponentName
    }, document.getElementById('portalError'));
  }
}
