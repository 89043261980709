import * as ko from 'knockout';
import { GroupDetailsDto } from '../Models/groupManagement/groupDetailsDto';
import { GroupMemberDto } from '../Models/groupManagement/groupMemberDto';
import { GroupCreateDto } from '../Models/groupManagement/groupCreateDto';
import { GroupDto } from '../Models/groupManagement/groupDto';
import DualListBoxItem from '../Components/dualListBox/types/DualListBoxItem';
import { GroupUpdateDto } from '../Models/groupManagement/groupUpdateDto';
import repositoryHelper from './repositoryHelper';
import { GroupSummaryDto } from '../Models/groupManagement/groupSummaryDto';

class GroupsRepository {
  private static readonly baseResourceUrl = 'api/groups/';

  public static getAllGroups = (businessId: string): Promise<GroupSummaryDto[]> => {
    return repositoryHelper.ajaxGet(GroupsRepository.baseResourceUrl + businessId)
      .then((serverGroups: any) => {

        return serverGroups.map((g: any) => {
          const groupDetails = new GroupDetailsDto(g.name, g.address, g.coordinate);
          const groupMembers: GroupMemberDto[] = g.groupMembers.map((gm: any) => {
            return new GroupMemberDto(
              gm.memberPersona.personaId,
              gm.memberPersona.personaDisplayName,
              gm.isGroupPrimaryContact);
          });
          return new GroupSummaryDto(g.groupId, groupDetails, ko.observable(groupMembers));
        });

      });
  };

  public static getAllGroupNames = (businessId: string): Promise<any> =>
    repositoryHelper.ajaxGet(`${GroupsRepository.baseResourceUrl}${businessId}/groupNames`);

  public static addNewGroup = (businessId: string, group: any, assignedItems: DualListBoxItem[]): Promise<boolean> => {
    const groupCreateDto: GroupCreateDto = GroupsRepository.mapToGroupCreateDto(businessId, group, assignedItems);
    return repositoryHelper.ajaxPost(GroupsRepository.baseResourceUrl, groupCreateDto);
  };

  public static updateGroup = (groupId: string, businessId: string, group: any, assignedItems: DualListBoxItem[]): Promise<GroupDto> => {
    const groupUpdateDto: GroupUpdateDto = GroupsRepository.mapToGroupUpdateDto(groupId, businessId, group, assignedItems);
    return repositoryHelper.ajaxPut(`${GroupsRepository.baseResourceUrl}${businessId}/${groupId}`, groupUpdateDto);
  };

  public static getGroupDetails = (businessId: string, groupId: string): Promise<GroupDto> =>
    repositoryHelper.ajaxGet(`${GroupsRepository.baseResourceUrl}${businessId}/${groupId}`);

  public static deleteGroup = (businessId: string, groupId: string): Promise<any> =>
    repositoryHelper.ajaxDelete(`${GroupsRepository.baseResourceUrl}${businessId}/${groupId}`);

  private static mapToGroupUpdateDto = (groupId: string, businessId: string, group: any, assignedItems: DualListBoxItem[]): GroupUpdateDto => {
    const groupDetails = new GroupDetailsDto(group.groupName(), group.address(), group.isLocationAssigned(), group.coordinate());
    const assignedGroupMembers = assignedItems
      .map((item: DualListBoxItem) => {
        return new GroupMemberDto(item.id, item.name, item.isChecked());
      });
    return new GroupUpdateDto(groupId, businessId, groupDetails, assignedGroupMembers);
  };
  private static mapToGroupCreateDto = (businessId: string, group: any, assignedItems: DualListBoxItem[]): GroupCreateDto => {
    const groupDetails = new GroupDetailsDto(group.groupName(), group.address(), group.isLocationAssigned(), group.coordinate());
    const assignedGroupMembers = assignedItems
      .map((item: DualListBoxItem) => {
        return new GroupMemberDto(item.id, item.name, item.isChecked());
      });
    return new GroupCreateDto(businessId, groupDetails, assignedGroupMembers);
  };
}
export default GroupsRepository;
