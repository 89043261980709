import anonymousRepositoryHelper from './anonymousRepositoryHelper';

function ResourceDataRepository() {

  const self = this;

  self.getResourceData = function (languageCode) {

    const resourceUrl = 'api/resourceData/' + languageCode;

    // This Web API call is performed without a bearer token as user won't be authenticated during registration
    return anonymousRepositoryHelper.ajaxGet(resourceUrl);
  };
}

export default new ResourceDataRepository();
