import contextData from '@/contextData';

export default class PrimeVueSettingsManager {
  public static async getSettings() {
    const locale = contextData.languageCode();
    const primeVueLocale = await import(`@/VueCore/locales/primeVue/${locale.toLowerCase()}.json`);
    const momentDateFormat = primeVueLocale.dateFormat
      .replace(/y/g, 'YY') // Day.js uses YY & YYYY for 2 and 4 digit years whereas PrimeVue uses y & yy
      .replace(/m/g, 'M') // Day.js uses M for month digit whereas PrimeVue uses m
      .replace(/d/g, 'D'); // Day.js uses D for day digit whereas PrimeVue uses d
    contextData.calendarDateTimeFormat = `${momentDateFormat} HH:mm:ss`;
    return {
      locale: {
        ...primeVueLocale
      }
    };
  }
}