import UploadModel from '@/Models/uploadModel';
import { UploadType } from '@/Types/Enums/uploadType';

export default class PartnerUploadModel {
  uploadId: string;
  requestId: string;
  uploadToken: string;
  addedTimestamp: string;
  fileName: string;
  size: number;
  fileRelativePath: string;
  personaDisplayName: string;

  rootFolderName: string;
  comments: string | [];
  nativeBrowserFile: File;
  ajaxRequest: any;

  uploadStatuses: string[];
  type: UploadType;

  cameraName: string | null;
  statusName: string;
  progress: number | null;
  bytesUploaded: number;
  userIsCurrentlyUploading: boolean;

  hasChanged: boolean;
  isAuthorising: boolean;

  uploadManagerUploadModel: UploadModel;

  getSortingValue(fieldName: string) {
    switch (fieldName) {
      case 'addedTimestamp':
        return this.addedTimestamp;
      case 'fileName':
        return this.fileName;
      case 'size':
        return this.size;
      case 'statusName':
        return this.statusName;
      case 'userName':
        return this.personaDisplayName;
      case 'path':
        return this.fileRelativePath;
    }
  }
}