import repositoryHelper from '@/Repositories/repositoryHelper';
import contextData from '@/contextData';
import { SelfResponseSummaryDto, SelfResponseSummary } from '@/Models/selfResponseSummary';
import { FilteredRequestSummaryRequestDto } from '@/Models/filteredRequestSummaryRequestDto';
import { FilteredRequestSummaryResponseDto } from '../Models/filteredRequestSummaryResponseDto';
import { FilterItemDto } from '@/Components/filterPanel/Models/filterItemDto';
import { InitialFilterCriteriaDto } from '@/Components/filterPanel/Models/initialFilterCriteriaDto';
import PageResultModel from '@/Models/pageResultModel';

export default class SelfResponseSummaryRepository {
  private static readonly baseResourceUrl = 'api/selfresponsesummary/';

  public static getInitialFilter = (): Promise<InitialFilterCriteriaDto> =>
    repositoryHelper
      .ajaxGet(`${SelfResponseSummaryRepository.baseResourceUrl}${contextData.userData.business.businessId}/initialFilterCriteria`);

  public static getSelfResponseSummaries = (
    filterCriteria: FilterItemDto[],
    sortField: string,
    sortOrder: string,
    page: number,
    pageSize: number): Promise<PageResultModel> => {

    const uri = `${SelfResponseSummaryRepository.baseResourceUrl}${contextData.userData.business.businessId}`;

    const filteredRequest = new FilteredRequestSummaryRequestDto(
      contextData.userData.business.businessId,
      filterCriteria,
      sortField,
      sortOrder,
      page,
      pageSize);

    return new Promise((resolve, reject) => {
      return repositoryHelper
        .ajaxPost(uri, filteredRequest)
        .then((serverPageResult: FilteredRequestSummaryResponseDto) => {
          const responseModels = serverPageResult.results.map((responseSummary: SelfResponseSummaryDto) => {
            return new SelfResponseSummary(responseSummary);
          });

          const pageResult = new PageResultModel(serverPageResult, responseModels);

          resolve(pageResult);
        })
        .catch((jqXhr: any) => {
          reject(jqXhr);
        });
    });
  };
}