import { defineRule, configure } from 'vee-validate';
import { required, max } from '@vee-validate/rules';
import { validateAgainstMasks } from './maskValidator';
import resourceHelper from '@/Utils/resourceHelper';
import moment from 'moment';

const applyDefaultVueValidationRules = () => {

  configure({
    validateOnInput: true
  });

  defineRule('required', (value: any, params: any) => {
    const isValid = required(value);

    if (!isValid) {
      return params.errorMessage ? resourceHelper.getString(params.errorMessage) : resourceHelper.getString('RequiredFieldRuleValidationMessage');
    }

    return true;
  });

  defineRule('textConfigurableField', (value: any, { maskPrompt, mustValidateMask, validationMasks }: any) => {

    const isValid = validateAgainstMasks(value, validationMasks);

    if (isValid) {
      return true;
    }

    if (!mustValidateMask) {
      return ' ';
    }

    const translatedValError = resourceHelper.getString('MustMatch');
    if (!maskPrompt) {
      const translatedValMask = resourceHelper.getString('ValidationMask');
      return `${translatedValError} ${translatedValMask}`;
    }

    return `${translatedValError} ${maskPrompt}`;
  });

  defineRule('numericConfigurableField', (value: any) => {

    if (!value || value.trim().length === 0) {
      return true;
    }

    const regex = /^[+-]?\d{1,16}(\.\d{1,2})?$/gm;
    const isValid = value.toString().trim().match(regex);

    if (!isValid) {
      return resourceHelper.getString('TheValueMustBeANumber');
    }

    return true;
  });

  defineRule('max', (value: any, params: any) => {
    const isValid = max(value, params);
    if (!isValid) {
      return resourceHelper.getString('MaxLengthValidationMessage', { '0': (params.length > 0 ? params[0] : params) });
    }

    return true;
  });

  defineRule('dateFormat', (value: Date | string, { format }: any) => {

    if (!value || value.toString().trim().length === 0) {
      return true;
    }

    if (typeof value === 'object') {
      return true;
    }
    let isValid = false;
    if (format) {
      isValid = moment(value, format, true).isValid();
      // Prime Vue calendar allows to enter simplified format D/M/YYYY
      if (!isValid) {
        const simplifiedFormat = format
          .replace('MM', 'M')
          .replace('DD', 'D');
        isValid = moment(value, simplifiedFormat, true).isValid();
      }
    } else {
      isValid = moment(value).isValid();
    }
    if (!isValid) {
      return resourceHelper.getString('InvalidDateFormat');
    }
    return true;
  });
};

export { applyDefaultVueValidationRules };