import repositoryHelper from './repositoryHelper';

class ReportRepository {
  private static readonly baseResourceUrl = 'api/report/';

  public static getRequestReport(reportParams: any): Promise<any> {
    const resourceUrl = this.baseResourceUrl + reportParams.requestId + '/requestReport';
    return repositoryHelper.ajaxPostBinaryData(resourceUrl, reportParams, []);
  }

  public static getUploadFolderReport(reportParams: any): Promise<any> {
    const resourceUrl = this.baseResourceUrl + reportParams.selfResponseId + '/uploadFolderReport';
    return repositoryHelper.ajaxPostBinaryData(resourceUrl, reportParams, []);
  }
}

export default ReportRepository;
