export function createValidator(rules: DtoValidatorRule[]): (value: any) => boolean | string {
  const validator = (value: any) => {
    return validate(value, rules);
  };

  return validator;
}

export function isFieldRequired(rules: DtoValidatorRule[]): boolean {
  if (!rules || !rules.length) {
    return false;
  }

  const requiredRule = rules.filter(f => !!f.required)[0];
  return !!requiredRule;
}

export interface DtoValidatorRuleSettings {
  message: string;
  params: any | undefined;
}

export interface DtoValidatorRule {
  required: DtoValidatorRuleSettings | undefined;
  minLength: DtoValidatorRuleSettings | undefined;
  maxLength: DtoValidatorRuleSettings | undefined;
  equal: DtoValidatorRuleSettings | undefined;
  mustBeTrue: DtoValidatorRuleSettings | undefined;
}

function validate(value: any, rules: DtoValidatorRule[]) {
  if (!rules?.length) {
    return true;
  }

  for (const rule of rules) {
    const requiredResult = validateRequired(value, rule);
    if (requiredResult !== true) {
      return requiredResult;
    }

    const minLengthResult = validateMinLength(value, rule);
    if (minLengthResult !== true) {
      return minLengthResult;
    }

    const maxLengthResult = validateMaxLength(value, rule);
    if (maxLengthResult !== true) {
      return maxLengthResult;
    }

    const equalResult = validateEqual(value, rule);
    if (equalResult !== true) {
      return equalResult;
    }

    const mustBeTrueResult = validateMustBeTrue(value, rule);
    if (mustBeTrueResult !== true) {
      return mustBeTrueResult;
    }
  }

  return true;
}

function validateRequired(value: string, rule: DtoValidatorRule): boolean | string {
  if (!rule.required) {
    return true;
  }

  if (!value?.trim()?.length) {
    return rule.required.message;
  }

  return true;
}

function validateMinLength(value: string, rule: DtoValidatorRule): boolean | string {
  if (!rule.minLength) {
    return true;
  }

  if ((value?.length ?? 0) < rule.minLength.params) {
    return rule.minLength.message;
  }

  return true;
}

function validateMaxLength(value: string, rule: DtoValidatorRule): boolean | string {
  if (!rule.maxLength) {
    return true;
  }

  if ((value?.length ?? 0) > rule.maxLength.params) {
    return rule.maxLength.message;
  }

  return true;
}

function validateEqual(value: string, rule: DtoValidatorRule): boolean | string {
  if (!rule.equal) {
    return true;
  }

  if (value !== rule.equal.params) {
    return rule.equal.message;
  }

  return true;
}

function validateMustBeTrue(value: boolean, rule: DtoValidatorRule): boolean | string {
  if (!rule.mustBeTrue) {
    return true;
  }

  if (value !== true) {
    return rule.mustBeTrue.message;
  }

  return true;
}