import repositoryHelper from './repositoryHelper';
import anonymousRepositoryHelper from './anonymousRepositoryHelper';

function SystemSettingsRepository() {

  const self = this;
  const resourceBaseUrl = 'api/systemSettings';

  /**
   * Get the URL of the Identity Server logon page for the Portal
   * @return {Promise<AuthenticationSettings>}
   */
  self.getAuthenticationSettings = function () {

    const resourceUrl = resourceBaseUrl + '/authenticationSettings';

    // This Web API call is performed without a bearer token as user won't yet be authenticated
    return anonymousRepositoryHelper.ajaxGet(resourceUrl)
        .then(serverData => {
          return {
            authority: serverData.authority,
            clientId: serverData.clientId,
            defaultLanguageCode: serverData.defaultLanguageCode
          };
        });
  };

  /**
   * Get registration settings including release mode flag
   * @return {Promise}
   */
  self.getRegistrationSettings = function () {

    const resourceUrl = resourceBaseUrl + '/registrationSettings';
    return anonymousRepositoryHelper.ajaxGet(resourceUrl);
  };

  /**
   * Get reset password settings including release mode flag
   * @return {Promise}
   */
  self.getResetPasswordSettings = function () {

    const resourceUrl = resourceBaseUrl + '/resetPasswordSettings';
    return anonymousRepositoryHelper.ajaxGet(resourceUrl);
  };

  /**
   * Get portal settings including upload url and in release mode flag
   * @return {Promise}
   */
  self.getPortalSettings = function () {

    const resourceUrl = resourceBaseUrl + '/portalSettings';
    return repositoryHelper.ajaxGet(resourceUrl);
  };

  /**
   * Get privacy policy settings including release mode flag
   * @return {Promise}
   */
  self.getPrivacyPolicySettings = function () {

    const resourceUrl = resourceBaseUrl + '/privacyPolicySettings';
    return anonymousRepositoryHelper.ajaxGet(resourceUrl);
  };
}

export default new SystemSettingsRepository();
