import ko from 'knockout';
import constants from '../constants';
import contextData from '../contextData';
import uploadFileLimitResolver from '@/Utils/uploadFileLimitResolver';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import FileListConverter from '@/Utils/fileListConverter';
import { UploadOption } from '@/Types/Enums/uploadOption';
import { UploadType } from '@/Types/Enums/uploadType';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import logger from '@/Utils/logger';

function RequestUploadValidation() {

  const self = this;

  /**
   * @typedef {Object} resourceInfo
   * @property {string} key The key which is presented in the BusinessPortal.Resources project.
   * @property {Object} [params] A set of parameters used in resource string.
   */
  /**
   * Checks if the uploads for a request are in the correct state to allow the request to be submitted.
   * If the validation fails, a resourceInfo object for the failure message to display to the user is returned
   * else if everything is OK, null is returned.
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @param {bool} areUploadsOptional Flag indicates whether at least one uploaded file is required
   * @return {resourceInfo|null}
   */
  self.getStatusValidationResourceInfo = function (uploads, areUploadsOptional = false) {

    if (uploads.length === 0) {
      if (!areUploadsOptional) {
        return { key: 'NoFilesUploaded' };
      }

      return null;
    }

    const statuses = uploads.map(function (upload) {
      return upload.statusName();
    });

    const containsAuthorising = statuses.indexOf(constants.uploadStatuses.authorising) > -1;
    const containsPending = statuses.indexOf(constants.uploadStatuses.pending) > -1;
    const containsUploading = statuses.indexOf(constants.uploadStatuses.uploading) > -1;
    const containsFailed = statuses.indexOf(constants.uploadStatuses.failed) > -1 ||
      statuses.indexOf(constants.uploadStatuses.failedAuthorisation) > -1;

    if (containsAuthorising || containsPending || containsUploading) {
      return { key: 'UploadsStillInProgress' };
    }

    if (containsFailed) {
      return { key: 'FailedUploadsExist' };
    }

    return null;
  };

  /**
   * Perform validation to check if new uploads can be added. If the validation fails, a resourceInfo object for
   * the failure message to display to the user is returned else if everything is OK, null is returned.
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @return {resourceInfo|null}
   */
  self.getAddUploadsValidationResourceInfo = function (fileList, uploads) {

    if (fileList.length === 0) {
      return { key: 'UploadNoFilesFound' };
    }

    if (containsZeroSizeItems(fileList)) {
      return { key: 'EmptyFoldersAndFilesNotAllowed' };
    }

    if (isCctvFolderDuplicated(fileList, uploads)) {
      return { key: 'UploadFolderAlreadyExists' };
    }

    const maxFileSize = contextData.portalSettings.maximumUploadSize;
    if (doAnyFileExceedAllowedSize(FileListConverter.fileListToArray(fileList), maxFileSize)) {
      const formattedFileSize = getFormattedFileSize(maxFileSize);
      return { key: 'MaxFileSizeExcess', params: { maxFileSize: formattedFileSize } };
    }

    const maxCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;
    if (isCctvFolderExceedsMaximumSize(fileList, maxCctvFolderUploadSize)) {
      const formattedCctvFolderSize = getFormattedFileSize(maxCctvFolderUploadSize);
      return { key: 'MaxFolderSizeExcess', params: { maxFolderSize: formattedCctvFolderSize } };
    }

    return null;
  };

  /**
   * Returns upload limit info for UploadLimitModal messages
   * @param {UploadParentItemType} itemType Request/response item type
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {number} uploads Array of UploadModel for each file that already exists
   * @return {LimitInfo}
   */
  self.getUploadLimit = (itemType, fileList, uploads) => {
    const limitInfo = uploadFileLimitResolver.createLimitInfo(uploadFileLimitResolver.getUploadFileLimitByRequestType(itemType),
        uploads, fileList.length, uploads + fileList.length);
    return limitInfo;
  };

  /**
   * Returns file duplicates for single files or files from folders
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @param {UploadParentItemType} uploadParentItemType Type of entity owned uploads (request or self-response)
   * @return {Object<File[], File[]>}
   */
  self.getFileDuplicates = (fileList, uploads, uploadParentItemType) => {
    const filesToAdd = FileListConverter.fileListToArray(fileList);

    const duplicates = [];
    const notDuplicates = [];
    ko.utils.arrayForEach(filesToAdd, file => {
      const isDuplicate = uploads.some(existedUpload => {
        if (existedUpload.type === UploadTypeResolver.getTypeFromNativeFile(file, uploadParentItemType)) {
          if (file.webkitRelativePath) {
            return existedUpload.fileRelativePath === file.webkitRelativePath;
          } else {
            return existedUpload.fileName === file.name;
          }
        }

        return false;
      });
      if (isDuplicate) {
        duplicates.push(file);
      } else {
        notDuplicates.push(file);
      }
    });

    return {
      duplicates,
      notDuplicates
    };
  };

  /**
   * Check if upload option is zip
   * @param {string} uploadOption
   * @return {boolean}
   */
  self.isZipOptionSelected = uploadOption => {
    return uploadOption === UploadOption.selectZipFiles;
  };

  /**
   * Check if upload option is folder
   * @param {string} uploadOption
   * @return {boolean}
   */
  self.isFolderOptionSelected = uploadOption => {
    return uploadOption === UploadOption.selectFolder;
  };

  /**
   * Check if upload option is file
   * @param {string} uploadOption
   * @return {boolean}
   */
  self.isFileOptionSelected = uploadOption => {
    return uploadOption === UploadOption.selectFiles;
  };

  /**
   * Check if file is zip
   * @param {string} fileName
   * @return {boolean}
   */
  self.isFileZip = fileName => {
    return fileName.split('.').pop() === 'zip';
  };

  /**
   * Returns file zips for single files or files from folders
   * @param {File[]} fileList
   * @return {File[]}
   */
  self.getZipFiles = fileList => {
    return Array.from(fileList).filter(f => self.isFileZip(f.name));
  };

  /**
   * Returns non-zip files and upload option selected as zip.
   * @param {File[]} fileList
   * @return {File[]}
   */
  self.getNonZipFiles = fileList => {
    return Array.from(fileList).filter(f => !self.isFileZip(f.name));
  };

  /**
   * Check if zip files contains for single files or files from folders
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  self.containsZipFiles = fileList => {
    return self.getZipFiles(fileList).length > 0;
  };

  /**
   * Check if selected files contains non-zip files and upload option selected as zip.
   * @param {string} uploadOption Type of selected file
   * @param {number} nonZipFilesCount Non-zip files count
   * @return {boolean}
   */
  self.showContainsNonZipWarning = (uploadOption, nonZipFilesCount) => {
    return uploadOption === UploadOption.selectZipFiles && nonZipFilesCount > 0;
  };

  /**
   * Check if selected files contains zip files and upload option selected as folder/files.
   * @param {string} uploadOption Type of selected file
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  self.showContainsZipWarning = (uploadOption, fileList) => {
    return (uploadOption === UploadOption.selectFiles && fileList?.length > 1 && self.getZipFiles(fileList).length > 0) ||
      (uploadOption === UploadOption.selectFolder && self.containsZipFiles(fileList));
  };

  /**
   * Check if selected file is folder/zip or if single zip file.
   * @param {string} uploadOption Type of selected file
   * @param {File[]} fileList Native browser FileList object
   * @return {boolean}
   */
  self.showContentTypeSelectionModal = (uploadOption, fileList) => {
    return !(uploadOption === UploadOption.selectFiles) ||
      (uploadOption === UploadOption.selectFiles && fileList.length === 1 && self.getZipFiles(fileList).length === 1);
  };

  /**
   * Check if request or self-response is completing and show warning to not allow upload.
   * @param {boolean} uploadParentItemIsCompleting Item is in process of completing/submitting
   * @param {string} uploadParentItemType Parent item type (e.g. UploadParentItemType.selfResponse)
   * @return {boolean}
   */
  self.showItemIsCompletingWarning = (uploadParentItemIsCompleting, uploadParentItemType) => {
    if (uploadParentItemIsCompleting === true) {
      logger.warning(uploadParentItemType === UploadParentItemType.partnerRequest ?
        'UploadIsNotAllowedForSubmittedRequest' : 'UploadIsNotAllowedForSubmittedUploadFolder');
      return true;
    }
    return false;
  };

  /**
 * Check if request or self-response is rejected and show warning to not allow upload.
 * @param {boolean} uploadParentItemIsDiscarding Item is in process of rejecting/deleting
 * @param {string} uploadParentItemType Parent item type (e.g. UploadParentItemType.selfResponse)
 * @return {boolean}
 */
  self.showItemIsDiscardingWarning = (uploadParentItemIsDiscarding, uploadParentItemType) => {
    if (uploadParentItemIsDiscarding === true) {
      logger.warning(uploadParentItemType === UploadParentItemType.partnerRequest ?
        'UploadIsNotAllowedForRejectedRequest' : 'UploadIsNotAllowedForDeletedUploadFolder');
      return true;
    }
    return false;
  };

  /**
   * Check if any of the files attempting to be added excess alloved size.
   * @param {FileList} filesToAdd Native browser FileList object
   * @param {number} maxFileSize
   * @return {boolean}
   */
  function doAnyFileExceedAllowedSize(filesToAdd, maxFileSize) {
    return filesToAdd.some(function (file) {
      return file.size > maxFileSize;
    });
  }

  /**
   * Checks if any FileList objects are for a folder or a zero length file (0 bytes)
   * @param {FileList} fileList Native browser FileList object
   * @return {boolean}
   * @private
   */
  function containsZeroSizeItems(fileList) {

    for (let i = 0; i < fileList.length; i++) {
      if (!fileList[i].size) {
        return true;
      }
    }

    return false;
  }

  function isCctvFolderDuplicated(fileList, uploads) {
    const filesToAdd = FileListConverter.fileListToArray(fileList);
    let folderAlreadyExist = false;

    ko.utils.arrayForEach(filesToAdd, fileToAdd => {
      if (fileToAdd.isCctv) {
        const folderName = fileToAdd.webkitRelativePath.substr(0, fileToAdd.webkitRelativePath.indexOf('/'));

        if (uploads.some(existedFile => {
          return existedFile.rootFolderName === folderName &&
            (existedFile.type === UploadType.cctvFile || existedFile.type === UploadType.deviceBackupFile);
        })) {
          folderAlreadyExist = true;
        }
      }
    });

    return folderAlreadyExist;
  }

  function isCctvFolderExceedsMaximumSize(fileList, maximumAllowedSize) {
    const filesToAdd = FileListConverter.fileListToArray(fileList);
    let folderSize = 0;
    for (const fileToAdd of filesToAdd) {
      if (fileToAdd.isCctv) {
        folderSize += fileToAdd.size;
        if (folderSize > maximumAllowedSize) {
          return true;
        }
      }
    }

    return false;
  }

  // TODO: Need to be refactored into helper
  function getFormattedFileSize(bytes) {

    if (bytes === 1) {
      return '1 Byte';
    }

    if (bytes < 1024) {
      return bytes + ' Bytes';
    }

    if (bytes < 1048576) {
      return roundDownToOneDp((bytes / 1024)) + ' KB';
    }

    if (bytes < 1073741824) {
      return roundDownToOneDp((bytes / 1048576)) + ' MB';
    }

    return roundDownToOneDp((bytes / 1073741824)) + ' GB';
  }

  function roundDownToOneDp(number) {
    number = number * 10;
    number = Math.floor(number);
    number = number / 10;

    return number.toFixed(1);
  }
}

export default new RequestUploadValidation();
