import * as ko from 'knockout';
import { Observable } from 'knockout';
import requestModelMapper from './requestModelMapper';
import resourceHelper from '@/Utils/resourceHelper';
import constants from '@/constants';
import { DataRequestFields } from './dataRequestFields';
import { RequestClaim } from './requestClaim';
import { RequestItemType } from '../Types/Enums/requestItemType';

class RequestModel {
  comments: Observable<string> = ko.observable().extend({
    maxLength: {
      params: 1000,
      message: resourceHelper.getString('CommentsMaxLength')
    }
  });
  reasonForRejection: Observable<string> = ko.observable().extend({
    required: {
      params: true,
      message: resourceHelper.getString('ReasonForRejectionRequired')
    },
    maxLength: {
      params: 1000,
      message: resourceHelper.getString('ReasonForRejectionMaxLength')
    }
  });

  // Uploads use custom validation but the comments and reason for rejection uses standard knockout
  // validation. The appropriate validation group should be checked depending on action.
  commentsClientErrors = ko.validation.group([this.comments]);
  rejectionClientErrors = ko.validation.group([this.reasonForRejection]);

  // Standard properties
  requestId: string;
  businessId: string;
  referenceNumber: string;
  requestStatus: number;
  requestStatusForDisplay: string;
  requestPriorityForDisplay: string;
  requestTypeDescription: string;
  requestDateTime: string;
  investigatorDisplayName: string;
  requiredByDate: string;
  hasResponse: boolean;
  description: string;
  statusJustChangedToInProgress: boolean;
  rowversion: string;
  isRejected: boolean;
  rejectionReason: string;
  requestItemType: RequestItemType;

  requestAgency: { agencyId: string; agencyName: string };
  dataRequestFields: DataRequestFields;

  // Dynamic form properties
  dynamicRequestFormDefinition: string;
  dynamicRequestFormData: string;
  dynamicResponseFormDefinition: string;
  dynamicResponseAllowUploads: boolean;
  dynamicResponseUploadsIncludeCameraSelection: boolean;
  dynamicCctvFolderExportExplanatoryText: string;
  dynamicResponseConfirmationText: string;
  dynamicResponseFormData: string;

  assignedPersonaId: string;
  assignedGroupId: string;

  allowedFeatures: { discussionMessaging: boolean };

  claims: RequestClaim[];

  constructor(serverModel: any) {
    this.comments(serverModel.comments);

    // Standard properties
    this.requestId = serverModel.requestId;
    this.businessId = serverModel.businessId;
    this.referenceNumber = serverModel.referenceNumber;
    this.requestStatus = serverModel.requestStatus;
    this.requestStatusForDisplay = serverModel.requestStatusForDisplay;
    this.requestPriorityForDisplay = serverModel.requestPriorityForDisplay;
    this.requestTypeDescription = serverModel.requestTypeDescription;
    this.requestDateTime = serverModel.requestDateTime;
    this.investigatorDisplayName = serverModel.investigatorDisplayName;
    this.requiredByDate = serverModel.requiredByDate;
    this.hasResponse = serverModel.hasResponse;
    this.description = serverModel.description;
    this.statusJustChangedToInProgress = serverModel.statusJustChangedToInProgress;
    this.rowversion = serverModel.rowversion;
    this.isRejected = (serverModel.requestStatus === constants.requestStatuses.rejected);
    this.rejectionReason = serverModel.rejectionReason;
    this.requestItemType = serverModel.requestItemType;

    this.requestAgency = mapAgency(serverModel.requestAgency);
    this.dataRequestFields = requestModelMapper.mapDataRequestFields(serverModel.dataRequestFields);

    this.claims = serverModel.claims || new Array <RequestClaim>();

    // Dynamic form properties
    this.dynamicRequestFormDefinition = serverModel.dynamicRequestFormDefinition;
    this.dynamicRequestFormData = serverModel.dynamicRequestFormData;
    this.dynamicResponseFormDefinition = serverModel.dynamicResponseFormDefinition;
    this.dynamicResponseAllowUploads = serverModel.dynamicResponseAllowUploads;
    this.dynamicResponseUploadsIncludeCameraSelection = serverModel.dynamicResponseUploadsIncludeCameraSelection;
    this.dynamicCctvFolderExportExplanatoryText = serverModel.dynamicCctvFolderExportExplanatoryText;
    this.dynamicResponseConfirmationText = serverModel.dynamicResponseConfirmationText;
    this.dynamicResponseFormData = serverModel.dynamicResponseFormData;

    this.assignedPersonaId = serverModel.assignedPersonaId;
    this.assignedGroupId = serverModel.assignedGroupId;

    this.allowedFeatures = {
      discussionMessaging: !!serverModel.allowedFeatures.discussionMessaging
    };
  }
}

function mapAgency(serverRequestAgency: any): { agencyId: string; agencyName: string } {
  return {
    agencyId: serverRequestAgency.agencyId,
    agencyName: serverRequestAgency.agencyName
  };
}

export default RequestModel;
