import PartnerUploadModel from '@/Models/partnerUploadModel';
import constants from '@/constants';
import { UploadType } from '@/Types/Enums/uploadType';

export default class PartnerZipFolderUploadModel {
  uploadStatuses: any;

  type: UploadType;
  uploads: PartnerUploadModel[];
  fileName: string;
  personaDisplayName: string;
  cameraName: string | null;
  comments: string | [];
  addedTimestamp: string;
  rootFolderName: string;
  uploadsCount: number;

  public get size():number {
    return this.uploads.reduce(function (total, upload) {
      return total + upload.size;
    }, 0);
  }

  public get bytesUploaded(): number {
    return this.uploads.reduce(function (total, upload) {
      return total + upload.bytesUploaded;
    }, 0);
  }

  public get statusName(): string {
    if (this.uploads.every(function (file) {
      return file.statusName === constants.uploadStatuses.uploaded;
    })) {
      return constants.uploadStatuses.uploaded;
    }
    if (this.uploads.some(function (file) {
      return file.statusName === constants.uploadStatuses.failedAuthorisation;
    })) {
      return constants.uploadStatuses.failedAuthorisation;
    }
    if (this.uploads.some(function (file) {
      return file.statusName === constants.uploadStatuses.cancelled;
    })) {
      return constants.uploadStatuses.cancelled;
    }
    if (this.uploads.some(function (file) {
      return file.statusName === constants.uploadStatuses.failed;
    })) {
      return constants.uploadStatuses.failed;
    }
    if (this.uploads.some(function (file) {
      return file.statusName === constants.uploadStatuses.authorising;
    })) {
      return constants.uploadStatuses.authorising;
    }
    if (this.uploads.some(function (file) {
      return file.statusName === constants.uploadStatuses.uploading;
    })) {
      return constants.uploadStatuses.uploading;
    }
    return constants.uploadStatuses.pending;
  }

  public get uploadId() {
    return this.uploads[0].uploadId;
  }

  get isAuthorising(): boolean {
    return this.statusName === this.uploadStatuses.authorising;
  }
  get progress(): number {
    return Math.floor(((this.bytesUploaded / this.size) * 100));
  }

  getSortingValue(fieldName: string) {
    switch (fieldName) {
      case 'addedTimestamp':
        return this.addedTimestamp;
      case 'fileName':
        return this.fileName;
      case 'size':
        return this.size;
      case 'statusName':
        return this.statusName;
      case 'userName':
        return this.personaDisplayName;
      case 'path':
        return this.rootFolderName;
    }
  }

  constructor(uploads: PartnerUploadModel[]) {
    this.uploadStatuses = constants.uploadStatuses;

    this.type = uploads[0].type;
    this.uploads = uploads;

    this.fileName = this.uploads[0].rootFolderName;
    this.personaDisplayName = this.uploads[0].personaDisplayName;
    this.cameraName = this.uploads[0].cameraName;
    this.comments = this.uploads[0].comments;
    this.addedTimestamp = this.uploads[0].addedTimestamp;
    this.rootFolderName = this.uploads[0].rootFolderName;
    this.uploadsCount = this.uploads.length;
  }
}