import VueDirective from '@/VueCore/directives/vueDirective';

const overlayClassName = 'disable-element-overlay';

const createOverlayElement = () => {
  const el = document.createElement('div');

  el.className = overlayClassName;

  return el;
};

const addOverlay = (parentElement: HTMLElement) => {
  // Do not add overlay if it already exists
  if (parentElement.getElementsByClassName(overlayClassName)?.length) {
    return;
  }

  const overlay = createOverlayElement();
  parentElement.style.position = 'relative';
  parentElement.append(overlay);
};

const removeOverlay = (parentElement: HTMLElement) => {
  const overlay = parentElement.getElementsByClassName(overlayClassName)[0] as HTMLElement;
  parentElement.style.removeProperty('position');
  overlay?.remove();
};

class DisableElementDirective implements VueDirective {
  name = 'disable-element';
  directive = {
    mounted(el, binding) {
      const directiveValue = binding?.value ?? true;

      if (directiveValue) {
        addOverlay(el);
      } else {
        removeOverlay(el);
      }
    },
    updated(el, binding) {
      const directiveValue = binding?.value ?? true;

      if (directiveValue) {
        addOverlay(el);
      } else {
        removeOverlay(el);
      }
    }
  };
}

export default new DisableElementDirective();