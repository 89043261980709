import repositoryHelper from '@/Repositories/repositoryHelper';
import ConfigurableFieldsModel from '@/Models/configurableFieldsModel';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

const baseResourceUrl = 'api/configurablefield/';

export default class ConfigurableFieldRepository {

  public static getUploadParentConfigurableFields(parentId: string, parentItemType: UploadParentItemType): Promise<ConfigurableFieldsModel> {
    const uri = `${baseResourceUrl}${parentItemType === UploadParentItemType.selfResponse ? 'selfresponse' : 'request'}/${parentId}`;
    return new Promise(function (resolve, reject) {
      repositoryHelper.ajaxGet(uri)
        .then((response: ConfigurableFieldsModel) => {
          resolve(response);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  }
}