import anonymousRepositoryHelper from './anonymousRepositoryHelper';

function ValidationRulesRepository() {

  const self = this;

  self.getValidationRules = function (resourceName) {

    const resourceUrl = 'api/validationRules/' + resourceName;
    return anonymousRepositoryHelper.ajaxGet(resourceUrl);
  };
}

export default new ValidationRulesRepository();
