import repositoryHelper from './repositoryHelper';

function AddressLookupRepository() {

  const self = this;
  const baseResourceUrl = 'api/addressLookup/';

  /**
   * @param {string} addressSearchTerm
   * @param {boolean} [orderByDistanceToCenter]
   * @return {Promise}
   */
  self.lookupAddress = function (addressSearchTerm, orderByDistanceToCenter) {

    orderByDistanceToCenter = (typeof orderByDistanceToCenter !== 'undefined') ? orderByDistanceToCenter : true;

    const resourceUrl =
      baseResourceUrl + '?addressSearchTerm=' + encodeURIComponent(addressSearchTerm) +
      '&orderByDistanceToCenter=' + orderByDistanceToCenter;

    return repositoryHelper.ajaxGet(resourceUrl)
        .then(function (addressSearchResults) {

          return new Promise(function (resolve) {
            resolve(addressSearchResults.slice(0, 10)); // Return the top 10 results
          });

        });
  };
}

export default new AddressLookupRepository();
