/**
 * Object to holds constants for the site to prevent the use of magic strings / magic values.
 */
const constants = {
  pageMode: {
    add: 'add',
    edit: 'edit'
  },
  uploadStatuses: {
    authorising: 'Authorising',
    pending: 'Pending',
    uploading: 'Uploading',
    uploaded: 'Uploaded',
    cancelled: 'Cancelled',
    failed: 'Failed',
    failedAuthorisation: 'FailedAuthorisation',
    initializing: 'Initializing'
  },
  requestStatuses: {
    inProgress: 'InProgress',
    completed: 'Completed',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    expired: 'Expired',
    failed: 'Failed'
  },
  selfResponseStatuses: {
    none: 'None',
    inProgress: 'InProgress',
    completed: 'Completed',
    failed: 'Failed',
    deleted: 'Deleted',
    sending: 'Sending'
  },
  requestAssigneeTypes: {
    user: 'User',
    group: 'Group'
  },
  tabNames: {
    locationTab: 'Location',
    usersTab: 'Users'
  },
  preRegisteredUserIconClass: 'fa-user-slash',

  /**
   * The maximum number of uploads after which the camera name and comment inputs
   * are not displayed due to rendering performance issues.
   */
  maxUploadsForCameraNameAndCommentInputs: 250,
  keyCodes: {
    tab: 9
  },
  dateTimeFormats: {
    serverDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ'
  },
  /**
   * Z index of the pop-up interface elements.
   * Since the sticky header has z-index = 11 all the pop-up elements on the page should have
   * z-index 10 or less to avoid overlapping the header.
   */
  dropdownZIndex: '10'
};

export default constants;
