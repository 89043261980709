function AssociatedFileModel(serverModel) {

  const self = this;

  // Properties
  self.id = serverModel.id;
  self.fileName = serverModel.originalFileName;
  self.fileSize = serverModel.fileSize;
  self.comments = serverModel.comments || null;
}

export default AssociatedFileModel;
