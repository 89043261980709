import ko from 'knockout';
import KnockoutVueWrapperOptions from '@/VueCore/utils/knockoutVueWrapper/knockoutVueWrapperOptions';
import VueAppBuilder from '@/VueCore/utils/vueAppBuilder';
import PrimeVue from 'primevue/config';
import PrimeVueSettingsManager from '@/VueCore/utils/primeVueSettingsManager';
import { reactive } from 'vue';

/**
 * Knockout component that holds a Vue.js component.
 *
 * To enable vue reactivity mechanism correctly using props created with 'defineReactiveProps' function
 * pass its 'vueComponentProps' property to the 'unwrappedProps' parameter.
 *
 * <component-name params="unwrappedProps: reactiveProps.vueComponentProps"></component-name>
 * */
export class KnockoutVueComponentWrapper {
  template: string;
  viewModel: object;
  vueComponent: any;

  constructor(vueComponent: any, opts: KnockoutVueWrapperOptions | undefined = undefined) {
    this.vueComponent = vueComponent;
    this.template = '<div></div>';

    this.viewModel = {
      createViewModel: (params: {
        props: object, unwrappedProps: object, events: Record<string, () => any>,
        slots: object
      }, componentInfo: Record<string, any>): object => {

        const propsData = params && params.props ? ko.toJS(params.props) : {};

        // ko.toJS during the mapping process executes all the nested object functions which may cause infinite loops and out of memory exceptions in some cases.
        // In this case the unwrappedProps property can be used to provide props that are not ko observables. When using the unwrappedProps ko.toJS step is skipped.
        // If you want to pass ko observables as props, please use the 'props' property instead.
        if (params && params.unwrappedProps) {
          Object.assign(propsData, params.unwrappedProps);
        }

        // Set events to the vue component. Events are going to be converted into "on{EventName}" format and passed
        // as props to the wrapped vue component.
        const eventsData = params && params.events ? ko.toJS(params.events) : {};
        const vueEvents: any = {};
        Object.entries(eventsData).forEach(([key, value]: [string, any]) => {
          vueEvents[this.formatEventName(key)] = value;
        });
        Object.assign(propsData, vueEvents);

        const slots = params && params.slots ? ko.toJS(params.slots) : {};

        if (typeof propsData !== 'object') {
          throw new Error('Parameter "props" is required to be an Object');
        }

        if (typeof eventsData !== 'object') {
          throw new Error('Parameter "events" is required to be an Object');
        }

        let vueAppBuilder: VueAppBuilder;
        PrimeVueSettingsManager.getSettings()
          .then(settings => {
            vueAppBuilder = new VueAppBuilder(this.vueComponent, reactive(propsData))
              .use(PrimeVue, settings);

            // Configure vue components
            opts?.components?.forEach(c => {
              vueAppBuilder.withComponent(c.name, c.component);
            });

            // Configure custom vue directives
            opts?.directives?.forEach(d => {
              vueAppBuilder.withDirective(d);
            });

            const instance = vueAppBuilder.build(componentInfo.element.firstElementChild);
            if (slots) {
              for (const [key, value] of Object.entries(slots)) {
                instance.$slots[key] = [{
                  text: value
                }];
              }
            }
            componentInfo.element.firstElementChild?.setAttribute('data-bind', 'stopBinding:true');
          });

        // The subscription to Knockout dispose event for DOM nodes that unmounts Vue.js component when it's no longer needed.
        // E.g. Vue.js component can be disposed when "ko-if" directive is used.
        ko.utils.domNodeDisposal.addDisposeCallback(componentInfo.element, () => {
          vueAppBuilder?.unmount();
        });

        // No need to have a specific viewModel.
        return {};
      }
    };
  }

  private formatEventName(eventName: string) {
    return 'on' + eventName.charAt(0).toUpperCase() + eventName.slice(1);
  }
}