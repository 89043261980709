import repositoryHelper from '@/Repositories/repositoryHelper';

const baseResourceUrl = 'api/linkingreference/';

export default class LinkingReferenceRepository {

  public static getAvailableRmsIds = (searchTerm: string) => {
    const uri = `${baseResourceUrl}search/rmsids`;
    return repositoryHelper.ajaxPost(uri, searchTerm);
  };

  public static getAvailableCadIds = (searchTerm: string) => {
    const uri = `${baseResourceUrl}search/cadids`;
    return repositoryHelper.ajaxPost(uri, searchTerm);
  };
}