import anonymousRepositoryHelper from './anonymousRepositoryHelper';
import repositoryHelper from './repositoryHelper';

function RegistrationRepository() {

  const self = this;

  /**
   * @param {string} registrationId
   * @param {boolean} isUserAuthenticated
   * @param {string} [languageCodeOverride]
   * @param {ErrorCode[]} errorCodesToIgnore Well-known error codes that if returned should not be logged
   * @return {Promise}
   */
  self.getPendingRegistrationData = function (
      registrationId,
      isUserAuthenticated,
      languageCodeOverride,
      errorCodesToIgnore
  ) {
    registrationId = registrationId || 'no-id';
    const authenticatedPath = isUserAuthenticated ? 'authenticated' : 'anonymous';
    let resourceUrl = `api/pendingRegistration/${authenticatedPath}/${registrationId}`;

    if (languageCodeOverride) {
      resourceUrl += '/' + languageCodeOverride;
    }

    if (isUserAuthenticated) {
      return repositoryHelper.ajaxGet(resourceUrl, errorCodesToIgnore);
    } else {
      return anonymousRepositoryHelper.ajaxGet(resourceUrl);
    }
  };

  /**
   *
   * @param {string} registrationId
   * @param {object} registrationData
   * @return {Promise}
   */
  self.submitRegistration = function (registrationId, registrationData) {

    const resourceUrl = 'api/registration/' + registrationId;

    // This Web API call is performed without a bearer token as user won't be authenticated
    return repositoryHelper.ajaxPost(resourceUrl, registrationData);
  };
}

export default new RegistrationRepository();
