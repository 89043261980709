import 'knockout.validation';

const defaultUntranslatedError = 'The value must be \'true\'';

export const mustBeTrue = {
  validator: function (value) {

    if (value === true) {
      return true;
    }

    return false;
  },
  message: defaultUntranslatedError
};
