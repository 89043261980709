import 'core-js/stable'; // polyfills for ECMAScript up to 2020
import 'regenerator-runtime/runtime'; // Standalone runtime for Regenerator-compiled generator and async functions
import 'bootstrap-sass'; // Include Bootstrap JS

import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import tokenManager from '@/Utils/tokenManager';
import systemSettingsRepository from '@/Repositories/systemSettingsRepository';
import config from '../config';
import portalStartup from './portalStartup';

/**
 * This is the initial loader for the portal. It checks that a user is authenticated and initialises
 * "portalStartup" if it is or redirects to the login screen if not.
 * @constructor
 */
function PortalAuthentication() {

  systemSettingsRepository.getAuthenticationSettings()
      .then(checkTokenIsValid)
      .catch(function (jqXhr) {

        logger.logAndThrowUntranslatedError(
            'An error occurred loading the site. Please try again later.',
            'Unable to retrieve the authentication settings and check the security token.',
            jqXhr);
      });

  function checkTokenIsValid(authenticationSettings) {
    const tokenManagerSettings = config.getTokenManagerSettingsForLogin(authenticationSettings);
    tokenManagerSettings.prompt = 'login';

    tokenManager.initAsync(tokenManagerSettings)
        .then(authTokenLanguageDetails => {
          if (tokenManager.validTokenExists()) {
            portalStartup.init(authTokenLanguageDetails);
          } else {
            redirectHelper.tokenLoginRedirect();
          }
        })
        .catch(err => {
          console.error('An error occurred initializing the token manager', err);
        });
  }
}

export default new PortalAuthentication();
