import { RequestClaim } from '@/Models/requestClaim';
import { SelfResponsePriority } from '@/Types/Enums/selfResponsePriority';

export default class SelfResponseModel {
  // Standard properties
  id: string;
  businessId: string;
  referenceNumber: string;
  responseStatus: number;
  responsePriority: SelfResponsePriority;
  selfResponseStatusForDisplay: string;
  selfResponsePriorityForDisplay: string;
  submittedDateTime: string;
  createdDateTime: string;
  rowversion: string;
  dynamicResponseFormDefinition: string;
  dynamicResponseFormData: string | null;
  dynamicResponseConfirmationText: string;
  dynamicResponseUploadGuidance: string;
  assignedPersonaId: string;
  assignedGroupId: string;
  creatorPersonaId: string;
  claims: RequestClaim[];
  internalReferenceNumber: string;
  dynamicResponseFormId: string;
  dynamicResponseFormVersion: string;
  rmsId: string;
  cadIds: string[];
  submittedUploadIds: Array<string>;

  constructor(serverModel: any) {
    this.id = serverModel.id;
    this.businessId = serverModel.businessId;
    this.referenceNumber = serverModel.referenceNumber;
    this.responseStatus = serverModel.responseStatus;
    this.selfResponseStatusForDisplay = serverModel.selfResponseStatusForDisplay;
    this.selfResponsePriorityForDisplay = serverModel.selfResponsePriorityForDisplay;
    this.submittedDateTime = serverModel.submittedDateTime;
    this.createdDateTime = serverModel.createdDateTime;
    this.rowversion = serverModel.rowversion;
    this.dynamicResponseFormDefinition = serverModel.dynamicResponseFormDefinition;
    this.dynamicResponseFormData = serverModel.dynamicResponseFormData;
    this.assignedPersonaId = serverModel.assignedPersonaId;
    this.assignedGroupId = serverModel.assignedGroupId;
    this.claims = serverModel.claims || [];
    this.internalReferenceNumber = serverModel.internalReferenceNumber;
    this.creatorPersonaId = serverModel.creatorPersonaId;
    this.dynamicResponseFormId = serverModel.dynamicResponseFormId;
    this.dynamicResponseFormVersion = serverModel.dynamicResponseFormVersion;
    this.dynamicResponseConfirmationText = serverModel.dynamicResponseConfirmationText;
    this.dynamicResponseUploadGuidance = serverModel.dynamicResponseUploadGuidance;
    this.responsePriority = serverModel.responsePriority;
    this.rmsId = serverModel.rmsId;
    this.cadIds = serverModel.cadIds;
    this.submittedUploadIds = serverModel.submittedUploadIds;
  }
}
