import { App, Component, Plugin, createApp, ComponentPublicInstance, h, ref } from 'vue';
import resourceHelper from '@/Utils/resourceHelper';
import VueDirective from '@/VueCore/directives/vueDirective';

export default class VueAppBuilder {
  private _app: App<Component>;
  public component: any = null;

  constructor(app: Component, rootProps?: any | null) {
    this._app = createApp({
      render: () => this.component = h(app, rootProps)
    });
  }

  private withLocalize(): VueAppBuilder {
    this._app.config.globalProperties.$localize = resourceHelper.getString;
    return this;
  }

  public use<Options extends unknown[]>(plugin: Plugin<Options>, options: Options): VueAppBuilder {
    this._app.use(plugin, options);
    return this;
  }

  public withComponent(componentName: string, component: Component) {
    this._app.component(componentName, component);
    return this;
  }

  public withDirective(vueDirective: VueDirective) {
    this._app.directive(vueDirective.name, vueDirective.directive);
    return this;
  }

  public build(targetElement: string): ComponentPublicInstance {
    this.withLocalize();
    return this._app.mount(targetElement);
  }

  public unmount() {
    this._app.unmount();
  }
}

/** The function is used to define reactive props for the VueAppBuilder.
*
* All the changes in the resulting object will be reflected in the built vue app by Vue reactivity mechanism.
*
* reactiveProps.vueComponentProps should be passed as props to the
* vue app builder:
*
* new VueAppBuilder(Component, reactiveProps.vueComponentProps)
 * @param {object} obj
 * @return {VueReactiveProps<T>}
* */
export function defineReactiveProps<TProps>(obj: TProps): VueReactiveProps<TProps> {
  const reactiveProps: VueReactiveProps<TProps> = {
    vueComponentProps: {}
  };

  for (const key of Object.keys(obj)) {
    reactiveProps.vueComponentProps[key] = ref(obj[key]);

    Object.defineProperty(reactiveProps, key, {
      get(): any {
        return reactiveProps.vueComponentProps[key].value;
      },
      set(v: any) {
        reactiveProps.vueComponentProps[key].value = v;
      }
    });
  }

  return reactiveProps;
}

/** The class simplifies creation of complex vue reactive props containing event handlers and v-models.
 * */
export class VueReactivePropsBuilder<T> {
  private readonly propsObject: any;
  private readonly vModelNames: string[];

  constructor() {
    this.propsObject = {};
    this.vModelNames = [];
  }

  public on(eventName: string, handler: (...args: Array<any>) => void) {
    const vueEventName = 'on' + eventName[0].toUpperCase() + eventName.slice(1);

    this.propsObject[vueEventName] = handler;
    return this;
  }

  public withProp<TProp>(name: string, defaultValue?: TProp) {
    this.propsObject[name] = defaultValue ? defaultValue : null;
    return this;
  }

  public withVModel<TModel>(name?: string, defaultValue?: TModel) {
    if (name) {
      return this.withNamedVModel(name, defaultValue);
    } else {
      return this.withDefaultVModel(defaultValue);
    }
  }

  public build(): VueReactiveProps<T> {
    const props = defineReactiveProps<T>(this.propsObject);

    if (!this.vModelNames.length) {
      return props;
    }

    // Set event handlers that change v-model properties values of the reactive vue object created
    // with defineReactiveProps()
    for (const vModel of this.vModelNames) {
      props[this.getVModelUpdateEventName(vModel)] = newValue => {
        props[vModel] = newValue;
      };
    }

    return props;
  }

  private withDefaultVModel<TModel>(defaultValue?: TModel) {
    this.withProp('modelValue', defaultValue);
    this.on(`update:modelValue`, (value: TModel) => {});
    this.vModelNames.push('modelValue');
    return this;
  }

  private withNamedVModel<TModel>(name: string, defaultValue?: TModel) {
    this.withProp(name, defaultValue);
    this.on(`update:${name}`, (value: TModel) => {});
    this.vModelNames.push(name);
    return this;
  }

  private getVModelUpdateEventName(name: string) {
    return `onUpdate:${name}`;
  }
}

export interface VueReactiveProps<T> extends T {
  vueComponentProps: any;
}