function BusinessModel(serverBusinessModel) {

  const self = this;

  self.businessId = serverBusinessModel.businessId;
  self.businessName = serverBusinessModel.businessName;
  self.businessAddress = serverBusinessModel.businessAddress;
  self.coordinate = serverBusinessModel.coordinate;
  self.accountType = serverBusinessModel.accountType;
}

export default BusinessModel;
