import resourceHelper from '../Utils/resourceHelper';
import { DataRequestFields } from './dataRequestFields';

class RequestModelMapper {

  mapDataRequestFields = (serverDataRequestField: DataRequestFields): DataRequestFields => {

    const cameraNames = serverDataRequestField.requestedCameraNames ||
      resourceHelper.getString('ReferToRequestDescription');

    return new DataRequestFields(
      serverDataRequestField.requestedStartTime,
      serverDataRequestField.requestedEndTime,
      cameraNames
    );
  };
}

export default new RequestModelMapper();
