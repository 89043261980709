const isSymbolMatchesToMaskSymbol = (valueSymbol: string, maskSymbol: string) => {
  if (!isNaN(parseInt(maskSymbol, 10))) {
    return !isNaN(parseInt(valueSymbol, 10));
  }

  if (maskSymbol.match(/[a-z]/i)) {
    return valueSymbol.match(/[a-z]/i);
  }

  return maskSymbol === valueSymbol;
};

const isValueMatchesToMask = (currentValue: string, mask: []) => {
  if (mask.length !== currentValue.length) {
    return false;
  }

  for (let i = 0; i < mask.length; i++) {
    if (!isSymbolMatchesToMaskSymbol(currentValue[i], mask[i])) {
      return false;
    }
  }

  return true;
};

export const validateAgainstMasks = (currentValue: string, masks: []) => {
  if (!currentValue || currentValue.length === 0 || !masks || masks.length === 0) {
    return true;
  }

  for (let i = 0; i < masks.length; i++) {
    if (isValueMatchesToMask(currentValue, masks[i])) {
      return true;
    }
  }

  return false;
};