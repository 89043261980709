import { SelfResponseStatus } from '@/Types/Enums/selfResponseStatus';

export interface SelfResponseSummaryDto {
  id: string;
  businessId: string | null;
  referenceNumber: string;
  internalReferenceNumber: string;
  responseStatus: SelfResponseStatus;
  selfResponseStatusForDisplay: string;
  selfResponsePriorityForDisplay: string;
  submittedDateTime: Date | null;
  createdDateTime: Date;
  assignedPersonaId: string;
  assignedGroupId: string;
  assignedPersonaDisplayName: string;
  assignedGroupDisplayName: string;
  createdByPersonaId: string;
  createdByPersonaDisplayName: string;
  discussionMessagesCount: string;
  rmsId: string;
  cadIds: string[];
}

export class SelfResponseSummary implements SelfResponseSummaryDto {
  public id: string;
  public businessId: string | null;
  public referenceNumber: string;
  public internalReferenceNumber: string;
  public responseStatus: SelfResponseStatus;
  public selfResponseStatusForDisplay: string;
  public selfResponsePriorityForDisplay: string;
  public submittedDateTime: Date | null;
  public createdDateTime: Date;
  public assignedPersonaId: string;
  public assignedGroupId: string;
  public assignedPersonaDisplayName: string;
  public assignedGroupDisplayName: string;
  public createdByPersonaId: string;
  public createdByPersonaDisplayName: string;
  public discussionMessagesCount: string;
  public rmsId: string;
  public cadIds: string[];

  constructor(dto: SelfResponseSummaryDto) {
    this.id = dto.id;
    this.businessId = dto.businessId;
    this.referenceNumber = dto.referenceNumber;
    this.internalReferenceNumber = dto.internalReferenceNumber;
    this.responseStatus = dto.responseStatus;
    this.selfResponseStatusForDisplay = dto.selfResponseStatusForDisplay;
    this.selfResponsePriorityForDisplay = dto.selfResponsePriorityForDisplay;
    this.submittedDateTime = dto.submittedDateTime;
    this.createdDateTime = dto.createdDateTime;
    this.assignedPersonaId = dto.assignedPersonaId;
    this.assignedGroupId = dto.assignedGroupId;
    this.assignedPersonaDisplayName = dto.assignedPersonaDisplayName;
    this.assignedGroupDisplayName = dto.assignedGroupDisplayName;
    this.createdByPersonaId = dto.createdByPersonaId;
    this.createdByPersonaDisplayName = dto.createdByPersonaDisplayName;
    this.discussionMessagesCount = dto.discussionMessagesCount;
    this.rmsId = dto.rmsId;
    this.cadIds = dto.cadIds;
  }
}