import PageResultModel from '../Models/pageResultModel';
import RequestSummaryModel from '../Models/requestSummaryModel';
import { FilteredRequestSummaryRequestDto } from '../Models/filteredRequestSummaryRequestDto';
import { FilteredRequestSummaryResponseDto } from '../Models/filteredRequestSummaryResponseDto';
import { FilterItemDto } from '../Components/filterPanel/Models/filterItemDto';
import { InitialFilterCriteriaDto } from '../Components/filterPanel/Models/initialFilterCriteriaDto';
import repositoryHelper from './repositoryHelper';
import contextData from '../contextData';


export default class RequestSummaryRepository {

  private static readonly baseResourceUrl = 'api/requestSummary/';

  public static getInitialFilter = (): Promise<InitialFilterCriteriaDto> =>
    repositoryHelper
      .ajaxGet(`${RequestSummaryRepository.baseResourceUrl}${contextData.userData.business.businessId}/initialFilterCriteria`);

  public static getRequests = (
    filterCriteria: FilterItemDto[],
    sortField: string,
    sortOrder: string,
    page: number,
    previousRequestId: string,
    pageSize: number): Promise<PageResultModel> => {

    const filteredRequest = new FilteredRequestSummaryRequestDto(
      contextData.userData.business.businessId,
      filterCriteria,
      sortField,
      sortOrder,
      page,
      pageSize);

    return new Promise((resolve, reject) => {
      return repositoryHelper
        .ajaxPost(`${RequestSummaryRepository.baseResourceUrl}${contextData.userData.business.businessId}`,
          filteredRequest)
        .then((serverPageResult: FilteredRequestSummaryResponseDto) => {
          const requestModels = serverPageResult.results.map((requestSummary: any) => {
            return new RequestSummaryModel(requestSummary, previousRequestId);
          });

          const pageResult = new PageResultModel(serverPageResult, requestModels);

          resolve(pageResult);
        })
        .catch((jqXhr: any) => {
          reject(jqXhr);
        });
    });
  };
}

